import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

import { CenterCreditBankPDFPreParser } from './centercredit-bank-pdf-preparser';

export class CenterCreditBankImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          isCurCase: [
            [
              0,
              {
                eq: 'Құжат электронды түрде жасалады / Документ сформирован электронно',
              },
            ],
          ],
          preParserConfigs: {
            interlineSpacingAccuracy: 10,
            verticalAlign: 'top',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Реттік',
                    },
                    nexWord: {
                      eq: 'Корр-тің',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'отправителя',
                    },
                    prevWord: {
                      eq: 'корр-та',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Реттік',
                      },
                      nexWord: {
                        eq: 'Корр-тің',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'Жиынтығы / Итого:',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'DD.MM.YYYY',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Күні / Дата', 1],
                replaceOne: [
                  {
                    from: '\n',
                    to: ' ',
                  },
                ],
              },
              comment: {
                column: ['Төлемнің мақсаты / Назначение\nплатежа', 11],
              },
              debit: {
                column: ['Дебет / Дебет', 7],
              },
              credit: {
                column: ['Кредит / Кредит', 8],
              },
              counterparty: {
                column: ['Корреспондент /\nКорреспондент', 5],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          isCurCase: [[0, { in: 'Документ сформирован электронно' }]],
          preParserConfigs: {
            rotate: true,
            pageSeparatorsLengthPx: 3,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Корр-тің' } }],
                to: [{ word: { in: 'отправителя' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'отправителя' } }],
                  count: 1,
                },
                {
                  from: [{ word: { startsWith: 'Жиынтығы / Итого:' } }],
                },
              ],
              define: [
                { regexp: { str: '^\\d+$' } },
                { regexp: { str: '^\\d+$' } },
                {
                  or: [
                    { dateFormat: 'DD.MM.YYYY' },
                    { dateFormat: 'DD.MM.YYYY HH:mm' },
                  ],
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Күні / Дата', 1],
                replaceOne: [{ from: '\n', to: ' ' }],
              },
              debit: { column: ['Дебет / Дебет', 7] },
              credit: { column: ['Кредит / Кредит', 8] },
              counterparty: { column: ['Корреспондент / Корреспондент', 5] },
              comment: {
                column: ['Төлемнің мақсаты / Назначение платежа', 11],
              },
            },
          },
        },
      },
      {
        preParser: new CenterCreditBankPDFPreParser(),
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            let lastStringArr =
              this.getFirstValidCellByColumn(['additionalData', 9])?.split(
                '\n',
              ) || [];
            let lastEmpty = 0;
            for (let i = 1; i < lastStringArr.length - 1; i++) {
              if (!/.+\/.+:/.test(lastStringArr[i])) {
                lastStringArr[lastEmpty] = `${lastStringArr[lastEmpty].replace(
                  '\n',
                  '',
                )} ${lastStringArr[i]}`;
                lastStringArr[i] = undefined;
              } else {
                lastEmpty = i;
              }
            }
            lastStringArr = lastStringArr.filter(Boolean);
            const counterparty = lastStringArr
              .find((str) =>
                str.startsWith('Корресп. атауы / Наименование корресп.:'),
              )
              ?.replace('Корресп. атауы / Наименование корресп.: ', '');
            const comment = lastStringArr
              .find((str) => str.startsWith('Төлем мақсаты / Назн. платежа:'))
              ?.replace('Төлем мақсаты / Назн. платежа: ', '');
            return {
              currency: this.getFirstValidCellByColumn(['Валютасы\nВалюта', 5]),
              date: this.getFirstValidCellByColumn(['Кұні\nДата', 6]),
              debit: this.getFirstValidCellByColumn([
                'Дебет сомасы\nСумма дебет',
                7,
              ]),
              credit: this.getFirstValidCellByColumn([
                'Кредит сомасы\nСумма кредит',
                8,
              ]),
              counterparty,
              comment,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          isDESCOpOrder: true,
          preParserConfigs: {
            verticalAlign: 'one-line',
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Құжат No' } }],
                to: [{ word: { in: 'Сумма кредит' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'Сумма кредит' } }],
                  cunt: 1,
                },
              ],
              define: [
                { regexp: { str: '^([\\s\\d]+\\.\\d+)$' } },
                { regexp: { str: '^([\\s\\d]+\\.\\d+)$' } },
                { dateFormat: 'DD.MM.YYYY' },
              ],
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                in: 'Есепшоттар бойынша айналым',
              },
            ],
          ],
          proceedCaseConfig: {
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Реттік № / № п/п',
                    },
                  ],
                ],
                count: 1,
              },
              {
                from: [
                  [
                    0,
                    {
                      eq: '',
                    },
                  ],
                  [
                    1,
                    {
                      eq: '',
                    },
                  ],
                  [
                    0,
                    {
                      eq: '',
                    },
                  ],
                  [
                    2,
                    {
                      eq: '',
                    },
                  ],
                  [
                    3,
                    {
                      eq: '',
                    },
                  ],
                  [
                    4,
                    {
                      eq: '',
                    },
                  ],
                  [
                    5,
                    {
                      eq: 'Жиынтығы / Итого:',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              credit: {
                column: ['Кредит / Кредит', 8],
              },
              debit: {
                column: ['Дебет / Дебет', 7],
              },
              dateAndTime: {
                column: ['Күні / Дата', 1],
              },
              comment: {
                column: ['Төлемнің мақсаты / \nНазначение платежа', 10],
              },
            },
          },
        },
      },
    ],
  };
}
