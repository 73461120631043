import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class ChaseBankParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          preParserConfigs: {
            CSVDelimiter: ',',
            simpleParse: true,
          },
          proceedCaseConfig: {
            fields: {
              date: { column: ['Posting Date', 1] },
              comment: { column: ['Description', 2] },
              sum: { column: ['Amount', 3] },
            },
          },
        },
      },
    ],
  };
}
