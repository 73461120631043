import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class BankPekaoImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'PLN',
          isCurCase: [
            [
              0,
              {
                eq: 'Operations history',
              },
            ],
            [
              4,
              {
                eq: 'Summary',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'middle',
            pageSeparatorsLengthPx: 3,
            interlineSpacingAccuracy: 4,
            separatorsDistanceModifier: 1.5,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Currency date',
                    },
                    nexWord: {
                      eq: 'Owner name',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'Documents',
                    },
                    prevWord: {
                      eq: 'Owner account',
                    },
                  },
                ],
              },
              delete: [
                {
                  from: [
                    {
                      word: {
                        startsWith: 'In trans-border settlement',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'DD.MM.YYYY',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              sum: {
                column: ['Amount / Currency\nDocuments', 5],
                split: {
                  by: {
                    regexp: {
                      str: '\\s',
                    },
                  },
                  get: 0,
                },
              },
              comment: {
                column: ['Operation title', 4],
              },
              date: {
                column: ['Currency date\nBooking date', 0],
                dateFormat: 'DD.MM.YYYY',
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: {
            verticalAlign: 'middle',
            pageSeparatorsLengthPx: 3,
            interlineSpacingAccuracy: 4,
            separatorsDistanceModifier: 1.5,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      in: 'Owner name',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      in: 'Documents',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        in: 'Documents',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        startsWith: 'In trans-border settlement',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        startsWith:
                          'https://www.pekaobiznes24.pl/webcorpo/do/operationHistory',
                      },
                    },
                  ],
                  to: [
                    {
                      prevWord: {
                        in: 'Bank Pekao SA',
                      },
                    },
                  ],
                },
              ],
              define: [
                {
                  regexp: {
                    str: '^(-?[\\d,]+\\.\\d{2}|-?[\\d,]+\\.\\d{2}\\s[A-z]{3})$',
                  },
                  trim: true,
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              sum: {
                column: ['Currency\nDocuments', 5],
                split: {
                  by: {
                    regexp: {
                      str: '\\s',
                    },
                  },
                  get: 0,
                },
              },
              currency: {
                column: ['Currency\nDocuments', 5],
                split: {
                  by: {
                    regexp: {
                      str: '\\s',
                    },
                  },
                  get: 1,
                },
              },
              comment: {
                column: ['Operation title', 4],
              },
              date: {
                or: [
                  {
                    column: ['Currency date', 0],
                    split: {
                      by: {
                        regexp: {
                          str: '\\s',
                        },
                      },
                      get: 1,
                    },
                  },
                  {
                    column: ['Currency date', 0],
                    split: {
                      by: {
                        regexp: {
                          str: '\\s',
                        },
                      },
                      get: 0,
                    },
                  },
                ],
              },
            },
          },
        },
      },
    ],
  };
}
