import { isEmpty } from 'lodash';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class AbankImportParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      // case 5
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [[0, { in: 'Підсумкова виписка за період' }]],
          preParserConfigs: {
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Дата та' } }],
                to: [
                  { word: { in: 'час операції' }, prevWord: { in: 'Сума' } },
                ],
              },
              delete: [
                {
                  to: [{ nextWord: { in: 'час операції' } }],
                  count: 1,
                },
              ],
              define: [{ dateFormat: 'DD.MM.YYYY' }],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата та\nчас операції', 0],
                replace: [{ from: '\n', to: ' ' }],
              },
              comment: { column: ['Призначення платежу', 2] },
              sum: { column: ['Сума', 3] },
            },
          },
        },
      },
      // case 1
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [[0, { in: 'Номер картки' }]],
          preParserConfigs: {
            maxInterlineSpacingPx: 10,
            verticalAlign: 'middle',
            pageSeparatorsLengthPx: 0.5,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    or: [
                      { word: { in: 'Сума' }, nextWord: { in: 'Сума' } },
                      {
                        word: { in: 'Дата і час' },
                        nextWord: { in: 'Сума у валюті' },
                      },
                    ],
                  },
                ],
                to: [
                  {
                    or: [
                      { word: { in: '(UAH)' }, nextWord: { in: '(UAH)' } },
                      { word: { in: '(UAH)' }, nextWord: { in: '(USD)' } },
                    ],
                  },
                ],
              },
              delete: [
                {
                  to: [{ nextWord: { in: 'Дата і час' } }],
                  count: 1,
                },
              ],
              define: [{ dateFormat: 'DD.MM.YYYY' }],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата і час\nоперації', 0],
                replace: [{ from: '\n', to: ' ' }],
              },
              comment: { column: ['Деталі операції', 2] },
              sum: { column: ['Сума у валюті\nкарти (UAH)', 4] },
            },
          },
        },
      },
      // case 2
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            maxInterlineSpacingPx: 40,
            interlineSpacingAccuracy: 1,
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Сума у' }, nextWord: { in: 'Сума у' } }],
                to: [
                  {
                    word: { in: '(UAH)' },
                    nextWord: { dateFormat: 'DD.MM.YYYY' },
                  },
                ],
              },
              delete: [
                {
                  to: [{ nextWord: { in: 'Дата і час' } }],
                  count: 1,
                },
              ],
              define: [{ dateFormat: 'DD.MM.YYYY' }],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата і час\nоперації', 0],
                replace: [{ from: '\n', to: ' ' }],
              },
              comment: { column: ['Деталі операції', 2] },
              sum: { column: ['Сума у валюті\nкарти (UAH)', 3] },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      // case 3
      {
        proceedCase: (importDocument) => {
          const firstHeaderLine = this.findString(/Дата/)?.raw;
          const lastBodyLine = this.findString(/ПРОВОДОК/)?.raw;
          importDocument = importDocument
            .slice(firstHeaderLine + 1, lastBodyLine)
            .map((el) => el.filter(Boolean))
            .filter((el) => !isEmpty(el));

          const targetValues = [
            ['Дата', 'ПРИЗНАЧЕННЯ', 'КОРРЕСПОНДЕНТ', 'Дебет', 'Кредит'],
          ];
          for (let line = 0; line < importDocument.length; line += 4) {
            const date = importDocument[line][0];
            const debit = importDocument[line][
              importDocument[line].length - 2
            ].replace(/([\r\n-])/g, '');
            const credit = importDocument[line][
              importDocument[line].length - 1
            ]?.replace(/([\r\n-])/g, '');
            const comment = importDocument[line + 1][1];
            const counterparty = importDocument[line + 3][1];
            targetValues.push([date, comment, counterparty, debit, credit]);
          }
          this.setDocumentHeader(targetValues[0]);
          this.setDocumentBody(targetValues.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата', 0]),
            debit: this.getFirstValidCellByColumn(['Дебет', 3]),
            credit: this.getFirstValidCellByColumn(['Кредит', 4]),
            counterparty: this.getFirstValidCellByColumn(['КОРРЕСПОНДЕНТ', 2]),
            comment: this.getFirstValidCellByColumn(['ПРИЗНАЧЕННЯ', 1]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isDESCOpOrder: true,
          withoutEmpty: true,
          isCurCase: [[0, { in: 'Підсумкова виписка' }]],
        },
      },
      // case 6
      {
        caseOptions: {
          defaultCurrency: 'USD',
          isDESCOpOrder: true,
          withoutEmpty: true,
          isCurCase: [[0, { in: 'Клієнт: ' }]],
          proceedCaseConfig: {
            withoutEmpty: true,
            delete: [
              {
                to: [
                  [0, { eq: 'Дата i час операції' }],
                  [1, { eq: 'Номер картки' }],
                  [2, { eq: 'Деталі операції' }],
                  [3, { eq: 'MCC' }],
                  [4, { eq: 'Сума в валюті картки (USD)' }],
                  [5, { eq: 'Сума в валюті операції' }],
                  [6, { eq: 'Валюта' }],
                  [7, { eq: 'Курс' }],
                  [8, { eq: 'Сума комісій (USD)' }],
                  [9, { eq: 'Сума кешбеку (UAH)' }],
                  [10, { eq: 'Залишок після операції' }],
                ],
                count: 1,
              },
            ],
            fields: {
              dateAndTime: { column: ['Дата i час операції', 0] },
              sum: { column: ['Сума в валюті картки (USD)', 4] },
              comment: { column: ['Деталі операції', 2] },
            },
          },
        },
      },
      // case 4
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isDESCOpOrder: true,
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата', 0],
                split: { get: 0, by: ' ' },
                add: [' ', { column: ['Час операції', 1] }],
              },
              counterparty: { column: ['Контрагент', 3] },
              comment: { column: ['Призначення платежу', 7] },
              sum: { column: ['Сума, грн', 8] },
            },
          },
        },
      },
    ],
  };
}
