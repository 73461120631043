export const ALLOW_CREATE_COMPANY_FROM_PREVIEW =
  'allowCreateCompanyFromPreview';
export const SHOW_PARTIAL_REPAYMENT_BUTTON = 'showPartialRepayment';
export const SHOW_DEBIT_CREDIT_CHOOSE_HISTORY_PERIOD =
  'showDebitCreditChooseHistoryPeriod';
export const SHOW_FINTABLO_IMPORT = 'showFintabloImport';
export const SHOW_1C_IMPORT = 'show1CImport';

export const SHOW_GMAIL_SIGNUP = 'showGmailSignUp';
export const SHOW_FACEBOOK_SIGNUP = 'showFacebookSignUp';
export const SHOW_LINKEDIN_SIGNUP = 'showLinkedinSignUp';
export const SHOW_TWITTER_SIGNUP = 'showTwitterSignUp';
export const SHOW_DELETE_ACCOUNT = 'showDeleteAccount';
export const SHOW_UNLINKCARD = 'showUnlinkCard';
export const SHOW_CHANGE_PAYMENT_METHOD = 'showChangePaymentMethod';
export const SHOW_PAY_NOW = 'showPayNow';
export const SHOW_DOWNLOAD_PAYMENT = 'showDownloadPayment';
export const SHOW_PAYMENT_HISTORY = 'showPaymentHistory';
export const SHOW_DOWNLOAD_PAYMENT_RECEIPT = 'showDownloadPaymentReceipt';
export const SHOW_NEXT_PAYMENT = 'showNextPayment';
export const USE_CLEAR_FILTER_FIELDS = 'useClearFilterFields';
export const SHOW_SUBSCRIPTION_LEFT_DAYS = 'showSubscriptionLeftDays';
export const SHOW_ADDITIONAL_INFO_ON_IMPORT_DIALOG =
  'showAdditionInfoOnImportDialog';
export const USE_NEW_UX_LOGIC_CREATE_ITEM = 'useNewUXLogicCreateItem';
export const SHOW_SUGGEST_TARIFF_LABEL = 'showSuggestTariffLabel';
export const SHOW_GROW_SURF_REFERRAL = 'showGrowsurfRefferal';
export const OPEN_REFERRAL_LINK_FROM_DEEP_LINK = 'openReferralLinkFromDeepLink';
export const SHOW_DETAILS_REFERRAL_LINK = 'showDetailsReferralLink';
export const SHOW_DOWNLOAD_ITEMS_BUTTON = 'showDownloadItemsButton';
export const OPERATION_TO_TRANSFER = 'operationToTransfer';
export const EXPORT_CHARTS_TO_PDF = 'exportChartsToPDF';
export const USE_PROJECTS_CHARTS = 'useProjectsCharts';
export const USE_CALENDAR_AIRBNB_VERSION = 'useCalendarAirbnbVersion';
export const SHOW_INVOICING_MENU = 'showInvoicingMenu';
export const ADD_INTERCOM_CONTACT = 'addIntercomContactAtFront';
export const GLOBAL_COUNTERPARTY_LIST = 'globalCounterpartyList';
export const SHOW_FILTERS_IN_CALENDAR = 'FiltersInCalendar';
export const SHOW_TC_MODAL = 'showTCModal';
export const SHOW_INVOICING_EMPTY_STATE = 'showInvoicingEmptyState';
export const SHOW_SETUP_AND_IMPLEMENT = 'showSetupAndImplement';
export const ON_BOARDING_V2 = 'onboardingV2';
export const SHOW_MAIN_CATEGORY_AS_NESTED_CATEGORY =
  'showMainCategoryAsNestedCategory';
export const USE_SETTINGS_V2 = 'useSettingsV2';
export const RECONNECT_SALTEDGE = 'reconnectSaltedge';
export const USE_CHRONOS_DECISION = 'useChronosdecision';
export const SHOW_SUBSCRIPTION_ACTIVE_TRIAL_BADGE =
  'showSubscriptionActiveTrialBadge';
export const SHOW_SUBSCRIPTION_TRIAL_BADGE_V2 = 'showSubscriptionTrialBadgeV2';
export const USE_EMOJI_INSTEAD_OF_AMOUNT = 'useEmojiInsteadOfAmount';
export const USE_AI_APP = 'useAiApp';
export const USE_DATE_OF_PAYMENT_AS_PERIOD = 'useDateOfPaymentAsPeriod';
export const USE_SORT_CATEGORIES_V2 = 'useSortCategoriesV2';
export const SHOW_CASH_GAP = 'showCashGap';
export const SHOW_ADDITIONAL_TIME_PERIODS = 'showAdditionalTimePeriods';
export const SHOW_SETTINGS_CUSTOMIZATION = 'showSettingsCustomization';
export const USE_LOGIN_BY_QR_CODE = 'useLoginByQRCode';
export const USE_SORT_ANALYTIC_MAIN_SCREEN = 'useSortAnalyticMainScreen';
export const SHOW_VIDEO_LINKS = 'showVideoLinks';
export const SHOW_INTEGRATION_BUTTON = 'showIntegrationButton';
export const SHOW_FULL_SCREEN_BUTTON = 'showFullScreenIcon';
export const SHOW_BLACK_FRIDAY_DISCOUNT = 'showBlackFridayDiscount';
export const SHOW_AUTO_RULES_SECTION = 'showAutoRulesSection';
export const USE_CURRENCY_PROVIDER = 'useCurrencyProvider';
export const EMPTY_STATES_V3 = 'emptyStatesV3';
export const SHOW_UZBEK_PAYMENT_BANNER = 'showUzbekPaymentBanner';
export const DISABLE_LIMIT_OF_AUTO_RULES_COUNT = 'disableLimitOfAutoRulesCount';
export const VIEW_AUTO_RULE_MULTI_VAR = 'viewAutoRuleMultiVar';
export const LONG_UPDATE_OPERATION = 'LongUpdateOperation';
export const SHOW_MONOBANK_IMPORT = 'showMonobankImport';
export const NEW_USER_FOR_CLIENT_AND_CATEGORIES_VIEW =
  'newUserForClientAndCategoriesView';
export const CALENDAR_COPY_ACCOUNTS = 'CalendarCopyAccounts';

export enum EmptyInvoicingStateVariant {
  showVideo = 'showVideo',
  withoutVideo = 'withoutVideo',
}
