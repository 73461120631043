import { OperationSubType, OperationType } from '../operations/types';

export type Integration = {
  loaderDone: boolean;
  loaderState: number;
  accountId: string;
  archived: boolean;
  companyId: string;
  consumptionSubType: OperationSubType;
  createdAt: string;
  createdBy: string;
  expiredAt?: string;
  bankId?: string;
  incomeSubType: OperationType;
  integrationCredentialsId: string;
  name: string;
  startDate: number;
  typeId: IntegrationTypeId;
  updatedAt: string;
  logoLink?: string;
  __v: number;
  _id: string;
};

export type IntegrationTypes = {
  _id: string;
  label: string;
};

export type IntegrationCredential = {
  _id: string;
  label: string;
  currencyId: string;
};

export type MonoItemCredential = {
  archived: boolean;
  companyId: string;
  createdAt: string;
  createdBy: string;
  requestId: string;
  updatedAt: string;
  currencyId: string;
  isGranted: boolean;
  __v: number;
  _id: string;
};

export type Privat24BusinessAccount = {
  acc: string;
  balance: string;
  currency: string;
  name: string;
};

export type InitialMonoCredentials = {
  item: MonoItemCredential;
  url: string;
};

export type MonoAccount = {
  id: string;
  name: string;
  balance: number;
  currencyId: string;
};

export type IntegrationAccounts = {
  id: string;
  amount: number;
  duration: number;
};

export type ApiKey = {
  archived: boolean;
  createdAt: string;
  createdBy: string;
  name: string;
  updatedAt: string;
  value: string;
  __v: number;
  _id: string;
};

export type Privat24BusinessIntegrationCredential = {
  accounts: Privat24BusinessAccount[];
  archived: false;
  autoClientId: string;
  companyId: string;
  createdAt: string;
  createdBy: string;
  token: string;
  updatedAt: string;
  __v: string;
  _id: string;
};

export type SaltedgeCredentials = {
  from_date?: number;
  takingDaysNumber: number;
  providerCode: string;
  redirectURL: string;
};

export type GetIntegrationAccountsPayload = {
  integrationId: string;
  connectionId: string;
};

export type GetIntegrationAccountsResponse = {
  accountId: string;
  iban: string;
  ownerName: string;
  name: string;
  amount: number;
  currency: string;
};

export type SaltedgeCredentialsPayload = {
  accountId: string;
  integrationId: string;
  connectionId: string;
};

export type SaltedgeReconnectPayload = {
  integrationCredentialsId: string;
  redirectURL: string;
};

export type SaltedgeUpdateConnectionPayload = {
  integrationCredentialsId: string;
  connectionId: string;
};

export type SaltedgeCredentialsResponse = {
  acceptingLink: string;
  integrationId: string;
  fromHistory?: boolean;
  connectionId?: string;
};

export type SaltedgeBankType = {
  code: string;
  country_code: string;
  id: string;
  logo_url: string;
  max_consent_days: number;
  maxTakingDaysNumber?: string;
  name: string;
  status: string;
  integrationTypeId?: IntegrationTypeId;
  isBelvo?: boolean;
  customHandler?(id: IntegrationTypeId): void;
};

export type SaltedgeAccount = {
  accountId: string;
  amount: number;
  currency: string;
  iban: string;
  name: string;
  ownerName: string;
};

export type PumbAccount = {
  accountId: string;
  balance: number;
  currency: string;
  iban: string;
  name: string;
  id: string;
};

export type IntegrationsState = {
  monoIsGranted: boolean;
  monoAccounts: MonoAccount[];
  integrations: Integration[];
  integrationTypes: IntegrationTypes[];
  integrationCredentials: IntegrationCredential[];
  integrationSaltedgeCredentials: SaltedgeCredentials[];
  integrationPrivat24BusinessCredentials: Privat24BusinessIntegrationCredential | null;
  initialMonoCredentials: InitialMonoCredentials | null;
  loadingIntegrationCredentials: boolean;
  loadingMonoIntegration: boolean;
  integrationInProgress: boolean;
  integrationAccounts: IntegrationAccounts[];
  telegramApi: {
    apiKeys: ApiKey[] | null;
    loading: boolean;
  };
  serverIp: string;
  privateCreateCredentialsError: boolean;
  saltedgeIntegrationId: string | null;
  pumbIntegrationId: string | null;
  fondyIntegrationId: string | null;
  westernBidIntegrationId: string | null;
  checkBoxIntegrationId: string | null;
  fakturowniaIntegrationId: string | null;
  iDokladIntegrationId: string | null;
  PosterIntegrationId: string | null;
  ukrsibIntegrationId: string | null;
  ukrsibCode: string;
  integrationNordigenCredentials: NordigenCredentials[];
  nordigenIntegrationId: string | null;
  belvoIntegrationId: string | null;
  payoneerCredentialsId: string | null;
};

export enum IntegrationTypeId {
  MonoPersonal = '602adb65b8be85e70cd1aafd',
  Private24Personal = '6007783dd06fcf7e292d5cd1',
  Private24Business = '6007783dd06fcf7e292d5cd2',
  Nordigen = '6127942f6fefd144f807790a',
  Saltedge = '623b5c5324438f1d73468e68',
  Pumb = '6140b63b384e8de59cdb96e6',
  Fondy = '615d8082c2cd2f52086b9716',
  FondyQ2 = '627d11ae2f76f932607d15ee',
  WesternBid = '61a78ae98458fed12b40c7a8',
  CheckBox = '61b30240679981c52b6e998a',
  Facturownia = '61f1795bb91886465a366fce',
  iDoklad = '61fd1c8b961e4ee9829e02e2',
  Poster = '61cda3be3d2004a14af81892',
  UkrSib = '626164565cf6a1f38ea9ff39',
  Payoneer = '61b220534d102bed1f3d5253',
  ABank = '612a3a19e7db4f207666566d',
  Belvo = '6698d108719e09df780fee21',
}

export type NordigenCredentials = {
  takingDaysNumber: number;
  bankId: string;
  redirectURL: string;
  countryCode: string;
};

export type NordigenCredentialsPayload = {
  accountId: string;
  integrationId: string;
  externalAccountCurrency?: string;
};

export type NordigenCredentialsResponse = {
  acceptingLink: string;
  integrationId: string;
  fromHistory?: boolean;
};

export type NordigenBankType = {
  id: string;
  logoLink: string;
  code?: string;
  logo_url?: string;
  maxTakingDaysNumber: number;
  max_consent_days?: number;
  name: string;
  integrationTypeId?: IntegrationTypeId;
  isBelvo?: boolean;
  customHandler?(id: IntegrationTypeId): void;
};

export type NordigenAccount = {
  accountId: string;
  amount: number;
  currency: string;
  iban: string;
  name: string;
  ownerName: string;
  externalAccountCurrency?: string;
};

export type BelvoInstitutionResource =
  | 'ACCOUNTS'
  | 'BALANCES'
  | 'EMPLOYMENT_RECORDS'
  | 'INCOMES'
  | 'INVOICES'
  | 'OWNERS'
  | 'RECURRING_EXPENSES'
  | 'RISK_INSIGHTS'
  | 'TRANSACTIONS'
  | 'TAX_COMPLIANCE_STATUS'
  | 'TAX_DECLARATIONS'
  | 'TAX_RETENTIONS'
  | 'TAX_RETURNS'
  | 'TAX_STATUS';

export type BelvoLinkUsernameType =
  | '001' //	Cédula de Ciudadanía
  | '002' //	Cédula de Extranjería
  | '003' //	Pasaporte
  | '004' //	Tarjeta de Identidad
  | '005' //	Registro Civil
  | '006' //	Número Identificación Personal
  | '020' //	NIT
  | '021' //	NIT Persona Natural
  | '022' //	NIT Persona Extranjera
  | '023' //	NIT Persona Jurídica
  | '024' //	NIT Menores
  | '025' //	NIT Desasociado
  | '030' //	Trj. Seguro Social Extranjero
  | '031' //	Sociedad Extranjera sin NIT en Colombia
  | '032' //	Fideicomiso
  | '033' //	RIF Venezuela
  | '034' //	CIF
  | '035' //	Número de Identidad
  | '036' //	RTN
  | '037' //	Cédula de Identidad
  | '038' //	DIMEX
  | '039' //	CED
  | '040' //	PAS
  | '041' //	Documento Único de Identidad
  | '042' //	NIT Salvadoreño
  | '100' //	Agência e conta
  | '101' //	Código do operador
  | '102' //	Cartão de crédito
  | '103'; //	CPF

export type BelvoInstitutionType = 'bank' | 'fiscal' | 'employment';

export type BelvoInstitutionFormField = {
  name:
    | 'username'
    | 'password'
    | 'username2'
    | 'username3'
    | 'password2'
    | 'token'
    | 'username_type';
  type: 'select' | 'password' | 'text' | 'string' | 'hidden';
  placeholder: string;
  label?: string;
  validation?: string;
  validation_message?: string;
  length?: string; // max chars
  optional?: boolean;
  features?: {}[]; // ?
  value?: string; // value for hidden type
  values?: {
    // values for select type
    code: string; // value
    label: string; // name
    validation?: string; // ?
    validation_message?: string; // ?
    placeholder?: string; // ?
  }[];
  pre_selected?: number; // index in "values" array, that must be default
};

export type BelvoAccount = {
  id: string;
  name: string;
  balance: number;
  currency: string;
};

export type BelvoRedirectData = {
  stale_in?: string;
  fetch_resources: BelvoInstitutionResource[];
  callback_url: string;
  locale?: string;
  institution: string;
  integration_type: string;
  identification_info?: IdentificationInfoDto[];
  logoLink: string;
};

export type BelvoRedirectDataResp = {
  redirectURL: string;
  integrationId: string;
};

export type BelvoIntegrationId = {
  integrationId: string;
};

export type BelvoLinkPayload = {
  integrationId: string;
  linkId: string;
};

export type BelvoBankType = {
  id: number;
  name: string;
  type: BelvoInstitutionType;
  code: string | null;
  name_code: string;
  country_codes: string[];
  logo_url: string;
  integration_type: 'openfinance' | 'credentials';
  resources: BelvoInstitutionResource[];
  form_fields: BelvoInstitutionFormField[];
  maxTakingDaysNumber: number;
  isBelvo?: boolean;

  integrationTypeId?: IntegrationTypeId;
  customHandler?(id: IntegrationTypeId): void;
};

export type BelvoCredentials = {
  institution: string;
  username: string;
  password?: string;
  username2?: string;
  username3?: string;
  password2?: string;
  token?: string;
  fetch_resources: BelvoInstitutionResource[];
  username_type?: string; // BelvoLinkUsernameType
};

export type BelvoCredentialsPayload = {
  accountId: string;
  integrationId: string;
};

export class IdentificationInfoDto {
  type?: string; // example: "CPF"
  number?: string; // example: "76109277673"
  name?: string; // example: "Ralph Bragg"
}
